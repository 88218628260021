import React, { useRef, useEffect, Suspense, lazy } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ProtectedRoute from './utils/ProtectedRoute';

import ScrollReveal from './utils/ScrollReveal';

// Layouts
// import LayoutDefault from './layouts/LayoutDefault';

// Pages
// import Default from "./pages/homes/Default";
import Business from "./pages/business/index";
import Funnel from "./pages/business/funnel";

// import Marketplace from "./pages/marketplace/index";
// import TermsOfService from "./pages/business/TermsOfService";
// import PrivacyPolicy from "./pages/business/PrivacyPolicy";
// import Press from "./pages/business/Press";

/// Business Profile


import Plans from "./pages/user/Plans";

import UDashboard from "./pages/user/Dashboard";
import BusinessVideos from "./pages/user/BusinessVideos";
import BusinessMediaManager from "./pages/user/BusinessMediaManager";


import Account from "./pages/user/Account";
import DetailedInfo from "./pages/user/DetailedInfo";
import SellerPhoto from "./pages/user/SellerPhoto";

// import BuyerProfile from "./pages/user/BuyerProfile";
import Security from "./pages/user/Security";
import Schedule from "./pages/user/Schedule";
import Reviews from "./pages/user/Reviews"
import Message from "./pages/user/Message";
import Stats from "./pages/user/Stats";
import BillingHistory from "./pages/user/BillingHistory"
import TagManager from "./pages/user/TagManager"

import SellerStories from "./pages/user/SellerStories"
import CheckOut from "./pages/user/CheckOut"
import CreatePost from "./pages/user/CreatePost";
import ManagePost from "./pages/user/ManagePost";


import UpdateCheckOut from "./pages/user/UpdateCheckOut"



// 
//// Buyer Profile

// import BuyerProfileOverview from "./pages/user/BuyerProfileOverview"
// import WriteReviews from "./pages/user/WriteReviews"
// import AddPhotos from "./pages/user/AddPhotos"

// import BuyerBookmark from "./pages/user/BuyerBookmark"
// import BuyerCheckIn from "./pages/user/BuyerCheckIn"
// import BuyerFollowers from "./pages/user/BuyerFollowers"
// import BuyerFollowing from "./pages/user/BuyerFollowing"
// import BuyerFriends from "./pages/user/BuyerFriends"
// import BuyerMessage from "./pages/user/BuyerMessage"
// import BuyerNewsletter from "./pages/user/BuyerNewsletter"
// import BuyerReviews from "./pages/user/BuyerReviews"




////
// import ListingGallery from "./pages/ListingGallery";

// Old Page
// import Home from "./pages/homes/Home";
// import Home2 from './pages/homes/Home2';
// import Home3 from './pages/homes/Home3';
// import Home4 from './pages/homes/Home4';
// import Home5 from './pages/homes/Home5';
// import AllCategories from './pages/categories/AllCategories';
// import AllLocations from './pages/categories/AllLocations';
// import TopPlaces from "./pages/categories/TopPlaces";
// import ListingGrid from "./pages/listings/ListingGrid";
// import ListingList from "./pages/listings/ListingList";
// import ListMapView from "./pages/listings/ListMapView";
// import ListMapView2 from "./pages/listings/ListMapView2";
// import ListLeftSidebar from "./pages/listings/ListLeftSidebar";
// import ListRightSidebar from "./pages/listings/ListRightSidebar";
// import ListingDetails from "./pages/listings/ListingDetails";
// import AddListing from "./pages/listings/AddListing";
// import UserProfile from "./components/other/account/UserProfile";
// import TopAuthors from "./pages/TopAuthors";
// import Dashboard from "./pages/dashboard/Dashboard";
// import Booking from "./pages/dashboard/Booking";
// import BookingConfirmation from "./pages/dashboard/BookingConfirmation";
// import Invoice from "./pages/dashboard/Invoice";
// import PricingPlan from "./pages/PricingPlan";
// import About from "./pages/About";
// import Faq from "./pages/FAQ";
// import Contact from "./pages/Contact";
// import RecoverPassword from "./pages/RecoverPassword";
// import BlogFullWidth from "./pages/blogs/BlogFullWidth";
// import BlogGrid from "./pages/blogs/BlogGrid";
// import BlogLeftSidebar from "./pages/blogs/BlogLeftSidebar";
// import BlogRightSidebar from "./pages/blogs/BlogRightSidebar";
// import BlogDetail from "./pages/blogs/BlogDetail";
import BusinessLogin from "./pages/BusinessLogin";
// import SignUp from "./pages/SignUp";
import BusinessSignUp from "./pages/BusinessSignUp";
import BusinessRecoverPassword from "./pages/BusinessRecoverPassword";
import VerifySellerAccount from "./pages/VerifySellerAccount";
import Error from "./pages/Error";
import useToken, { uInfo } from './useToken';
// import Pusher from 'pusher-js'
import $ from 'jquery'
import { ApiUrl, MixpanelToken } from './config';
import axios from 'axios';
import ReactGA from 'react-ga';
import ViewStory from "./pages/user/StoryGallery";
import mixpanel from 'mixpanel-browser';
mixpanel.init(MixpanelToken, { debug: false });
const App = (props) => {

  const childRef = useRef();
  let location = useLocation();
  const { setToken } = useToken();

  // if(!token) {
  //   return <Login setToken={setToken} />
  // }

  useEffect(() => {
    document.body.classList.add('is-loaded')


    axios.get(ApiUrl + 'getGoogleAnalyticsKey', {}, {
      headers: {}
    })
      .then(function (res) {
        let key = 'UA-192424311-1';
        if (res.data.success === true) {
          key = res.data.lists.google_analytics;
        }
        ReactGA.initialize(key);

        if (uInfo()) {
          ReactGA.set({ userId: uInfo().analytic_id });
          mixpanel.identify(uInfo().analytic_id);
        } else {
          ReactGA.set({ userId: `BizVisitor-${Math.floor(Math.random() * 90000) + 10000}` });
          mixpanel.identify(`Visitor-${Math.floor(Math.random() * 90000) + 10000}`);
        }
        mixpanel.track(window.location.href, { 'source': window.location.href });
        ReactGA.pageview(window.location.href);
      })
      .catch(function (error) {
        console.log(error);
      });



    // window.scrollTo(0, 0);
    $('html, body').animate({ scrollTop: 0 }, 1000);
    // childRef.current.init();
    // if (location.pathname != '/message') {
    //   let pusher = new Pusher('3086524ac1732fefac3c', {
    //     authEndpoint: `${ApiUrl}pusher/auth`,
    //     cluster: "mt1"
    //   })
    //   pusher.unsubscribe('my-channel');
    // }
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>

            {/* //////////// Login Account */}

            {/* Business Profile */}

            <ProtectedRoute path="/dashboard" component={UDashboard} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/business-video" component={BusinessVideos} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/media-manager/:date?" component={BusinessMediaManager} requiredRoles={[0, 2]} />

            <ProtectedRoute path="/account" component={Account} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/detailed-info" component={DetailedInfo} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/security" component={Security} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/schedule" component={Schedule} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/reviews" component={Reviews} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/message" component={Message} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/stats" component={Stats} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/billing-history" component={BillingHistory} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/tag-manager" component={TagManager} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/business-photos" component={SellerPhoto} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/stories" component={SellerStories} requiredRoles={[0, 2]} />
            <AppRoute path="/seller-view-story" component={ViewStory} />
            <ProtectedRoute path="/checkout/:id" component={CheckOut} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/update/card/information" component={UpdateCheckOut} requiredRoles={[0, 2]} />

            <ProtectedRoute path="/create-post" component={CreatePost} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/manage-post" component={ManagePost} requiredRoles={[0, 2]} />

            <AppRoute path="/plans" component={Plans} />

            {/* buyer Profile */}
            {/* <ProtectedRoute path="/update-buyer-profile" component={BuyerProfile} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-profile" component={BuyerProfileOverview} requiredRoles={[1]} />
            <ProtectedRoute path="/write-review/:id" component={WriteReviews} requiredRoles={[0, 1, 2]} />
            <ProtectedRoute path="/add-photos/:id" component={AddPhotos} requiredRoles={[0, 1, 2]} />
            <ProtectedRoute path="/buyer-bookmarks" component={BuyerBookmark} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-check-in" component={BuyerCheckIn} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-followers" component={BuyerFollowers} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-following" component={BuyerFollowing} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-friends" component={BuyerFriends} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-message" component={BuyerMessage} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-newsletters" component={BuyerNewsletter} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-reviews" component={BuyerReviews} requiredRoles={[1]} /> */}

            {/* /////////////// login user ///////////// */}
            <AppRoute exact path="/" component={Business} />
            <AppRoute path="/katikacontentcreation" component={Funnel} />
            {/* <AppRoute path="/directories" component={Directories} /> */}
            {/* <AppRoute path="/marketplace" component={Marketplace} /> */}
            {/* <AppRoute path="/index1" component={Home} /> */}
            {/* <AppRoute path="/index2" component={Home2} />
            <AppRoute path="/index3" component={Home3} />
            <AppRoute path="/index4" component={Home4} />
            <AppRoute path="/index5" component={Home5} />
            <AppRoute path="/all-categoriesss" component={AllCategories} />
            <AppRoute path="/all-locations" component={AllLocations} />
            <AppRoute path="/top-place" component={TopPlaces} />
            <AppRoute path="/listing-grid" component={ListingGrid} />
            <AppRoute path="/listing-list" component={ListingList} />
            <AppRoute path="/listing/:id?" component={ListingList} />
            <AppRoute path="/listing-gallery/:id" component={ListingGallery} />
            <AppRoute path="/list-map-view" component={ListMapView} />
            <AppRoute path="/list-map-view2" component={ListMapView2} />
            <AppRoute path="/list-left-sidebar" component={ListLeftSidebar} />
            <AppRoute path="/list-right-sidebar" component={ListRightSidebar} />
            <AppRoute path="/biz/:id" component={ListingDetails} />
            <AppRoute path="/add-listing/new" component={AddListing} />
            <AppRoute path="/user-profile" component={UserProfile} />
            <AppRoute path="/top-author" component={TopAuthors} />
            <AppRoute path="/dashboard123" component={Dashboard} />
            <AppRoute path="/booking" component={Booking} />
            <AppRoute path="/booking-confirmation" component={BookingConfirmation} />
            <AppRoute path="/invoice" component={Invoice} />
            <AppRoute path="/pricing" component={PricingPlan} />
            <AppRoute path="/about" component={About} />
            <AppRoute path="/faq" component={Faq} />
            <AppRoute path="/contact" component={Contact} />
            <AppRoute path="/recover" component={RecoverPassword} />
            <AppRoute path="/blog-full-width" component={BlogFullWidth} />
            <AppRoute path="/blog-grid" component={BlogGrid} />
            <AppRoute path="/blog-left-sidebar" component={BlogLeftSidebar} />
            <AppRoute path="/blog-right-sidebar" component={BlogRightSidebar} />
            <AppRoute path="/blog-single" component={BlogDetail} />
            <AppRoute path="/login"> <Login setToken={setToken} />
          </AppRoute> */}
            <AppRoute path="/business-login"> <BusinessLogin setToken={setToken} />
            </AppRoute>
            <AppRoute path="/verify-business-account/:email" component={VerifySellerAccount} />
            <AppRoute path="/recover" component={BusinessRecoverPassword} />
            {/* <AppRoute path="/sign-up" component={SignUp} /> */}
            <AppRoute path="/business-sign-up" component={BusinessSignUp} />
            <AppRoute component={Error} />
          </Switch>
        </Suspense>
      )} />
  );
}

export default App;